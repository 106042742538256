.jstree-default {
    .jstree-clicked {
    background-color: #beebff;
    }  
}
.jstree-default {
    .jstree-hovered {
    background-color: #e7f4f9;
    }  
}
