/* Data Tables */
.dataTables_wrapper {
  padding-left: 0;
  padding-right: 0;
  label {
    font-size: .8125rem;
  }
  select {
    @extend .form-control;
    padding: .4rem;
    border: 1px solid #e4e9f0;
    outline: 0;
  }
  .dataTables_length {
    select {
      min-width: 65px;
      margin-left: .25rem;
      margin-right: .25rem;
    }
  }
  .dataTable {
    .btn {
      padding: 0.1rem 1rem;
      vertical-align: top;
    }
    thead {
      th {
        border-bottom-width: 0;
      }
      .sorting,
      .sorting_asc,
      .sorting_desc,
      .sorting_asc_disabled,
      .sorting_desc_disabled {
        &:before,
        &:after {
          line-height: 2.5;
          font-family: Material Design Icons;
          font-size: .65rem;
        }
        &:before {
          content: "\F05D";
          right: 1.2em;
        }
        &:after {
          content: "\F045";
          right: 0.2em;
        }
      }
    }
  }
  .dataTables_paginate {
    margin-top: 20px;
  }
  .dataTables_info {
    font-size: $default-font-size;
  }
}

.custom-datatable {
  .dataTables_wrapper {
    select {
      @extend .form-control;
      padding: .4rem;
      border: 1px solid #e4e9f0;
      outline: 0;
    }
    .dataTables_length {
      select {
        min-width: 65px;
        width: 65px;
        height: 28px;
        margin-left: .25rem;
        margin-right: .25rem;
      }
      label {
        display: flex;
        justify-content:flex-start;
        align-items: center;
      }
    }
    .dataTables_filter {
      label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .form-control {
          width: 120px;
          height: 28px;
          margin-left: 10px;
        }
      }
    }
    .dataTables_paginate {
      margin-top: 20px;
      display: none;
    }
  }
}
