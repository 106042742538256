.list-item {
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid $border-color;

  &:first-child {
    padding-top: 30px;
  }

  &:last-child {
    border-bottom: none;
  }

  .preview-image {
    max-width: 80px;
    width: 80px;
    min-width: 80px;

    img {
      display: block;
      margin: auto;
    }
  }

  .content {
    width: calc(100% - 80px);
    padding-right: 30px;

    .user-name {
      margin-bottom: 0;
      margin-right: 10px;
      color: $text-muted;
      white-space: nowrap;
    }

    .time {
      color: $text-muted;
      margin-bottom: 3px;
    }

    .product-name {
      font-weight: 500;
      @include ellipsor;
    }

    .review-text {
      margin-bottom: 0;
      font-weight: 300;
      @include ellipsor;
      max-width: 95%;
    }
  }
}
